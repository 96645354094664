<template lang="pug">
.form-modal(
  v-if="showFormManager.firstStep"
  @mousedown="changeFormManagerVisibility({ hide: 'firstStep' })"
)
  .content(@mousedown.stop="")
    .header.om-bottom-divider.pb-4
      .button.om-close(
        @click="changeFormManagerVisibility({ hide: 'firstStep' })"
        style="position: absolute; top: 0; right: 0"
      ) &times;
      .om-row.header-inner
        .om-col.om-col-7
          .om-title-field {{ $t('addField') }}
          .om-select-field {{ $t('selectRecentFields') }}
        .om-col.om-col-5
          .button.button-primary.om-create-new(
            @click="changeFormManagerVisibility({ hide: 'firstStep', show: 'secondStep' })"
          ) + {{ $t('createNew') }}
    table
      thead
        tr.om-modal-tr.om-modal-tr-title
          th.om-modal-th {{ $t('name') }}
          th.om-modal-th {{ $t('type') }}
      tbody.om-table-scroll
        tr.om-modal-tr(v-for="entry in inputs")
          td.om-modal-td {{ entry['name'] }}
          td.om-modal-td {{ $t(entry['type']) }}
          td.om-modal-td.buttons
            .edit(v-if="canEditField(entry)" @click="editField(entry)")
              i.fa.fa-pencil.fa
            .button.button-primary.button-select(@click="addField(entry)") {{ $t('add') }}
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import { addInputDefaultOptions } from '@/editor/util';
  import { gqlClient as axios } from '@/editor/axios';

  export default {
    computed: {
      ...mapState(['inputs', 'showFormManager', 'selectedElement', 'templateSaveData']),
      fieldSelected() {
        return this.selectedField !== null;
      },
    },
    watch: {
      'showFormManager.firstStep': function (v) {
        if (v) {
          this.loadFields();
        }
      },
    },
    mounted() {
      if (this.showFormManager.firstStep) this.loadFields();
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      canEditField(entry) {
        return entry._id !== null;
      },
      addField(entry) {
        this.changeFormManagerVisibility({ hide: 'firstStep' });
        this.$bus.$emit('addElement', { customField: entry });
      },
      editField(entry) {
        this.changeFormManagerVisibility({
          hide: 'firstStep',
          show: 'thirdStep',
          selectedElementValue: entry,
        });
      },
      loadFields() {
        const { type, value } = this.templateSaveData;
        this.$bus.$emit('showAdminLoader', true);
        const templateQuery = `{fields: allTemplateFields(template: "${value}"){_id, customId ,name, type}}`;
        const variantQuery = `{fields: allFields{_id, customId ,name, type}}`;
        axios
          .get('', {
            params: {
              query: type === 'base' ? templateQuery : variantQuery,
            },
          })
          .then((r) => {
            const fields = r.data.data.fields;
            fields.forEach((r) => {
              if (this.$i18n.locale === 'hu') {
                if (r.customId === 'firstname') {
                  r.name = 'Keresztnév';
                } else if (r.customId === 'lastname') {
                  r.name = 'Vezetéknév';
                }
              }
            });
            this.$bus.$emit('addFields', addInputDefaultOptions(fields, this.selectedElement));
            this.$nextTick(() => {
              this.$bus.$emit('showAdminLoader', false);
            });
          });
      },
    },
  };
</script>
